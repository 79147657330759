import { kebabCase } from 'lodash'

import Breadcrumbs from '~/components/breadcrumbs/Breadcrumbs'
import MainLayout from '~/components/layouts/MainLayout'
import { TagsQueryResult } from '~/templates/blog/Tags'
import { PagePropsWithContext, WithRequired } from '~/types/helper'
import BlogPagination from '~/views/Blog/components/BlogPagination'
import BlogPostList from '~/views/Blog/components/BlogPostList'
import NavigationCategory from '~/views/Blog/components/NavigationCategory'
import NavigationTag from '~/views/Blog/components/NavigationTag'

import * as containerStyles from '../Blog.module.scss'

const Tags = ({ data, pageContext }: PagePropsWithContext<TagsQueryResult>) => {
  const { h1, url, description } = data.strapiTag as WithRequired<
    Queries.STRAPI_TAG,
    'h1'
  >
  const articles = data.allStrapiArticle.edges.map((article) => article.node)

  const tagInfo = description?.data?.childMarkdownRemark?.html

  return (
    <MainLayout isStaticChat scrollTop>
      <div className={containerStyles.blog}>
        <div className="container container-md">
          <div className="row">
            <div className="col-12">
              <div className={containerStyles.blog__breadcrumbsWrapper}>
                <Breadcrumbs
                  secondLevel="Blog"
                  secondLevelPath="/blog/"
                  current={`${h1}`}
                  currentPath={`/blog/tag/${kebabCase(url || '')}/`}
                />
              </div>
              <h1 className={containerStyles.blog__title}>{h1}</h1>
            </div>

            <NavigationCategory data={data} />
            <NavigationTag data={data} showMore />

            {tagInfo && (
              <div className="col-12">
                <div
                  className={containerStyles.blog__tagInfo}
                  dangerouslySetInnerHTML={{
                    __html: tagInfo,
                  }}
                />
              </div>
            )}

            <BlogPostList data={articles} withHeading />

            <BlogPagination pageContext={pageContext} />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Tags
